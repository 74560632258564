<template>
  <div class="image-slider" v-if="images.length">
    <div
      class="slider-container"
      :style="{ transform: `translateX(-${currentImageIndex * 100}%)` }"
    >
      <img
        v-for="(image, index) in images"
        :key="index"
        :src="image"
        :alt="`Bild ${index + 1}`"
      />
    </div>
    <button
      @click="prevImage"
      :disabled="currentImageIndex === 0"
      class="nav-button prev"
    >
      <span class="mdi mdi-chevron-left"></span>
    </button>
    <button
      @click="nextImage"
      :disabled="currentImageIndex === images.length - 1"
      class="nav-button next"
    >
      <span class="mdi mdi-chevron-right"></span>
    </button>
  </div>
</template>

<script>
export default {
  name: "ImageSlider",
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentImageIndex: 0,
      timer: null,
    };
  },
  methods: {
    nextImage() {
      if (this.currentImageIndex < this.images.length - 1) {
        this.currentImageIndex++;
      } else {
        this.currentImageIndex = 0;
      }
      this.resetTimer();
    },
    prevImage() {
      if (this.currentImageIndex > 0) {
        this.currentImageIndex--;
      } else {
        this.currentImageIndex = this.images.length - 1;
      }
      this.resetTimer();
    },
    startTimer() {
      this.timer = setInterval(() => {
        this.nextImage();
      }, 6000);
    },
    resetTimer() {
      clearInterval(this.timer);
      this.startTimer();
    },
  },
  mounted() {
    this.startTimer();
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
.image-slider {
  position: relative;
  width: calc(100% + 30px);
  margin: 0 -15px;
  height: 320px;
  overflow: hidden;

  .slider-container {
    display: flex;
    transition: transform 0.5s ease;
    height: 100%;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      flex-shrink: 0;
    }
  }

  .nav-button {
    position: absolute;
    bottom: 15px;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 2em;
    transition: opacity 0.3s ease;

    &.prev {
      left: 10px;
    }

    &.next {
      right: 10px;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &:not(:disabled):hover {
      opacity: 0.8;
    }
  }
}
</style>
