<template>
  <div class="size-selector">
    <div
      v-for="category in categories"
      :key="category.name"
      class="size-category"
    >
      <h4>{{ category.name }}</h4>
      <div class="size-list">
        <div v-for="size in category.sizes" :key="size.name" class="size-item">
          <div class="size-name">{{ size.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SizeSelector",
  data() {
    return {
      categories: [
        {
          name: "Größen",
          sizes: [
            { name: "100 x 60 x 200 cm" },
            { name: "120 x 60 x 200 cm" },
            { name: "150 x 60 x 200 cm" },
            { name: "180 x 60 x 200 cm" },
            { name: "200 x 60 x 220 cm" },
            { name: "220 x 60 x 220 cm" },
            { name: "240 x 60 x 220 cm" },
            { name: "300 x 60 x 220 cm" },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.size-selector {
  padding: 30px 0;

  h4 {
    color: var(--main);
    font-weight: 400;
  }

  .size-list {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;

    .size-item {
      width: calc(50% - 5px);
      margin-top: 10px;
      border: 1px solid #e0e0e0;
      padding: 5px 10px;
      display: inline-block;
    }
  }
}
</style>
