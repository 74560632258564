<template>
  <div class="product-description">
    <transition name="fade" mode="out-in">
      <div v-if="!expanded" key="teaser" class="teaser" v-html="teaser"></div>
      <div
        v-else
        key="full"
        class="full-description"
        v-html="fullDescription"
      ></div>
    </transition>
    <button v-if="hasMore" @click="toggleExpanded" class="toggle-button">
      {{ expanded ? "- Weniger anzeigen" : "+ Mehr anzeigen" }}
    </button>
  </div>
</template>

<script>
export default {
  name: "ProductDescription",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    processedDescription() {
      // Create a new div element and set its innerHTML to the product description
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = this.product.description;

      // Replace all block-level elements with their inner text content plus two newline characters
      const blockElements = tempDiv.querySelectorAll(
        "p, div, h1, h2, h3, h4, h5, h6, ul, ol, li, blockquote"
      );
      blockElements.forEach((el) => {
        el.insertAdjacentText("beforebegin", "\n\n");
        el.insertAdjacentText("afterend", "\n\n");
      });

      // Get the text content, which now includes our added newlines
      let processedText = tempDiv.textContent || tempDiv.innerText || "";

      // Replace multiple whitespace characters (including newlines) with a single space
      processedText = processedText.replace(/\s+/g, " ").trim();

      return processedText;
    },
    teaser() {
      const words = this.processedDescription.split(/\s+/);
      const teaserWords = words.slice(0, 10);
      return teaserWords.join(" ") + (this.hasMore ? "..." : "");
    },
    fullDescription() {
      return this.product.description;
    },
    hasMore() {
      return this.processedDescription.split(/\s+/).length > 10;
    },
  },
  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style lang="scss" scoped>
.product-description {
  margin-bottom: 30px;
  padding: 25px;
  background-color: #eee;

  .teaser,
  .full-description {
    margin-bottom: 0.5rem;
  }

  .toggle-button {
    background: none;
    border: none;
    color: var(--main);
    font-weight: 600;
    cursor: pointer;
    padding: 0;
    font-size: 0.9em;

    &:hover {
      text-decoration: underline;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease, max-height 0.5s ease;
  max-height: 1000px;
  overflow: hidden;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  max-height: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
  max-height: 1000px;
}
</style>
