import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ModelView from '../views/ModelView.vue'
import FaqView from '../views/FaqView.vue'
import WishlistView from '../views/WishlistView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: 'Startseite' }
  },
  {
    path: '/produkte',
    name: 'products',
    component: ModelView,
    meta: { title: 'Produkte' }
  },
  {
    path: '/produkte/:productName',
    name: 'product',
    component: ModelView,
    meta: { title: '' }  // We'll set this dynamically
  },
  {
    path: '/faq',
    name: 'faq',
    component: FaqView,
    meta: { title: 'FAQ' }
  },
  {
    path: '/merkliste',
    name: 'merkliste',
    component: WishlistView,
    meta: { title: 'Merkliste' }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // For the product page, we'll set the title in the component
  if (to.name !== 'product') {
    document.title = `${to.meta.title} | rauch Möbelwerke GmbH`
  }
  next()
})

export default router