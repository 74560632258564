<template>
  <div class="youtube-video">
    <div class="video-container" :style="{ paddingBottom: aspectRatio }">
      <iframe
        :src="embedUrl"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <figcaption v-if="title" class="video-title">{{ title }}</figcaption>
  </div>
</template>

<script>
export default {
  name: "YouTubeVideo",
  props: {
    videoId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    aspectRatio: {
      type: String,
      default: "56.25%", // 16:9 aspect ratio
    },
  },
  computed: {
    embedUrl() {
      return `https://www.youtube.com/embed/${this.videoId}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.youtube-video {
  width: 100%;
  max-width: 800px;
  margin: 0 auto 30px;

  .video-container {
    position: relative;
    width: 100%;
    height: 0;
    overflow: hidden;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid #e0e0e0;
    }
  }

  .video-title {
    font-size: 1em;
    color: #333;
    background-color: #e0e0e0;
    padding: 20px;
  }
}
</style>
