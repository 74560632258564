<template>
  <div class="question-accordion">
    <div class="question-head" @click="toggleAnswer">
      {{ question }}
    </div>
    <div class="question-body" v-show="isOpen">
      <div class="inner" v-html="answer"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FaqQuestion",
  props: {
    question: {
      type: String,
      required: true,
    },
    answer: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleAnswer() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style lang="scss">
.question-accordion {
  border-top: 1px solid #e0e0e0;

  .question-head {
    padding: 20px 0;
    cursor: pointer;
    position: relative;
    padding-right: 40px;
    font-weight: 500;

    &:hover {
      background-color: #e0e0e0;
    }

    &::after {
      content: "\F0140";
      font-family: "Material Design Icons";
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      transition: transform 0.3s ease;
    }
  }

  .question-body {
    padding: 0 0 20px;
    font-weight: 300;

    a {
      color: var(--main);
      font-weight: 500;
      display: inline-block;
      margin: 10px 0 20px;

      &::before {
        content: "→ ";
      }
    }
  }
}
</style>
