<template>
  <div class="home">
    <header>
      <h2 class="h2-icon">rauch Companion</h2>
    </header>
    <div class="content">
      <div class="home-buttons">
        <div class="home-button">
          <router-link to="/produkte" class="button">
            <div class="box-image">
              <img src="@/assets/images/products.jpg" />
            </div>
            <div class="box-content">
              <div class="box-inner">
                <div class="teaser_topline">Sortiment</div>
                <div class="teaser_headline h3">Unsere Produkte</div>
                <div class="teaser_text">
                  <p>
                    Entdecke unsere Produkte und finde das richtige Modell für
                    Dich!
                  </p>
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="home-button">
          <router-link to="/faq" class="button">
            <div class="box-image">
              <img src="@/assets/images/faq.jpg" />
            </div>

            <div class="box-content">
              <div class="box-inner">
                <div class="teaser_topline">Hilfe</div>
                <div class="teaser_headline h3">Häufige Fragen</div>
                <div class="teaser_text">
                  <p>Erhalte Unterstützung bei deinen Fragen und Problemen.</p>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "HomeView",
  components: {},
};
</script>

<style lang="scss" scoped>
.home {
  padding: 30px 0;
}

.home-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-top: 30px;

  .home-button {
    a {
      display: block;
      color: inherit;
      text-decoration: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;

      .box-image {
        position: relative;

        img {
          width: 100%;
          height: auto;
        }

        &::after {
          position: absolute;
          content: url(@/assets/edge-red-big.svg);
          top: 0;
          right: 0;
          width: calc(100% - 30px);
          max-width: 291px;
        }
      }

      .box-content {
        width: calc(100% - 30px);
        margin-left: 30px;
        background-color: var(--bgc);
        margin-top: -100px;
        padding-left: 30px;
        padding-top: 27px;
        position: relative;
        min-height: 120px;
        z-index: 10;

        .teaser_topline {
          color: var(--main);
          text-transform: uppercase;
          font-size: 0.9375rem;
        }

        .teaser_headline {
          margin-bottom: 10px;
          margin-top: 10px;
        }

        .teaser_text {
          font-family: "Flexo", sans-serif;
          font-size: 1rem;
          font-weight: 300;
          line-height: 1.5rem;
        }
      }
    }
  }
}
</style>
