<template>
  <nav class="navbar">
    <div class="navbar__left">
      <button v-if="!isHomeView" class="navbar__button" @click="goBack">
        <span class="mdi mdi-chevron-left"></span>
      </button>
      <button class="navbar__button" @click="toggleMenu">
        <span class="mdi mdi-menu"></span>
      </button>
    </div>
    <div class="navbar__center" @click="goToHome">
      <object
        class="navbar__logo"
        type="image/svg+xml"
        :data="require('@/assets/logo.svg')"
      >
        <img
          :src="require('@/assets/logo.svg')"
          alt="Rauch Logo"
          class="navbar__logo-fallback"
        />
      </object>
    </div>
    <div class="navbar__right">
      <button class="navbar__button" @click="goToMerkliste">
        <span class="mdi mdi-heart-outline"></span>
      </button>
      <button class="navbar__button" @click="goToProdukte">
        <span class="mdi mdi-magnify"></span>
      </button>
    </div>

    <div :class="{ navbar__menu: true, show: isMenuOpen }">
      <router-link to="/produkte" class="navbar__menu-item"
        >Produkte entdecken</router-link
      >
      <router-link to="/faq" class="navbar__menu-item"
        >Häufige Fragen</router-link
      >
      <router-link to="/merkliste" class="navbar__menu-item"
        >Merkliste</router-link
      >
      <a href="https://www.rauchmoebel.de/unternehmen" class="navbar__menu-item"
        >Über rauch Möbelwerke</a
      >
    </div>
  </nav>
</template>

<script>
export default {
  name: "RauchNavbar",
  data() {
    return {
      isMenuOpen: false,
    };
  },
  watch: {
    // Neuer Watcher für die Route
    $route() {
      this.isMenuOpen = false;
    },
  },
  computed: {
    isHomeView() {
      return this.$route.name === "home";
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    goToHome() {
      this.$router.push("/");
      this.isMenuOpen = false;
    },
    goToMerkliste() {
      this.$router.push("/merkliste");
      this.isMenuOpen = false;
    },
    goToProdukte() {
      this.$router.push("/produkte");
      this.isMenuOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: var(--bgc);
  border-bottom: 1px solid #e0e0e0;
  z-index: 999;

  .navbar__center {
    cursor: pointer;
    object,
    img,
    svg {
      pointer-events: none;
    }
  }

  &__center {
    display: flex;
    align-items: center;
    width: 50%;
    justify-content: center;
  }

  &__left {
    display: flex;
    align-items: center;
    width: 25%;
  }

  &__right {
    display: flex;
    align-items: center;
    width: 25%;
    justify-content: flex-end;
  }

  &__button {
    background: none;
    border: none;
    font-size: 1.2rem;
    color: #333;
    cursor: pointer;
    padding: 5px;
    margin: 0 5px;

    &:hover {
      color: #000;
    }
  }

  &__logo,
  &__logo-fallback {
    height: 40px;
    margin: 0 10px;
    cursor: pointer;
  }

  &__menu {
    position: fixed;
    top: 61px; // Anpassen je nach Höhe der Navbar
    left: 0;
    width: 100%;
    height: calc(100vh - 61px);
    background-color: var(--bgc);
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease;
    transform: translateX(-100%);

    &.show {
      transform: translateX(-0%);
    }
  }

  &__menu-item {
    padding: 20px;
    font-size: 1.1rem;
    color: #333;
    text-decoration: none;
    border-bottom: 1px solid #e0e0e0;
    cursor: pointer;

    &:hover {
      color: #000;
    }
  }
}

.mdi {
  font-size: 24px;
}
</style>
