<template>
  <div>
    <NavBar />
    <router-view />
  </div>
</template>

<script>
import NavBar from "@/components/main/NavBar.vue";

export default {
  name: "App",
  components: {
    NavBar,
  },
};
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}

:root {
  --main: #e3210f;
  --bgc: #fafafa;
  --lg_grey: #e0e0e0;
  --md_grey: #adadad;
  --dk_grey: #424242;
  --textcolor: #464646;
}

body {
  background-color: var(--bgc);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h2 {
  font-family: Cocogoose;
}

header {
  margin-bottom: 30px;

  h2 {
    position: relative;
    font-family: Cocogoose;
    font-weight: 600;
    margin: 0;
    font-size: 1.5rem;
    line-height: 2.25rem;

    &.h2-icon {
      padding-top: 40px;
      padding-left: 40px;
      padding-bottom: 10px;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        content: url("@/assets/rectangle-red.svg");
      }
    }
  }

  &.main-header {
    padding: 30px 0;
    border-bottom: 1px solid #e0e0e0;
    text-align: center;

    h2 {
      display: inline-block;
      margin: 0 auto;
    }
  }
}

.h3 {
  font-family: Cocogoose;
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 2.125rem;
  margin: 0;
}

body {
  margin: 0;
}

p {
  margin: 0 0 10px;

  :last-child {
    margin-bottom: 0;
  }
}

#app {
  font-family: "Flexo", -apple-system, "BlinkMacSystemFont", "Segoe UI",
    "Roboto", "Helvetica Neue", "Arial", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  padding: 60px 15px 30px;
}

/* You might want to remove or adjust these styles as they might conflict with the new navbar */
/*
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
*/

@font-face {
  font-family: Flexo;
  font-weight: 300;
  src: url(@/assets/fonts/Flexo_light.b67a5da9.otf) format("opentype");
}

@font-face {
  font-family: Flexo;
  font-weight: 500;
  src: url(@/assets/fonts/Flexo_medium.c1e9d87c.otf) format("opentype");
}

@font-face {
  font-family: Cocogoose;
  font-weight: 200;
  src: url(@/assets/fonts/CocogoosePro-UltraLight.ab31788e.ttf);
}
</style>
