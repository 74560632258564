<template>
  <div class="color-selector">
    <div
      v-for="category in categories"
      :key="category.name"
      class="color-category"
    >
      <h4>{{ category.name }}</h4>
      <div class="color-list">
        <div
          v-for="color in category.colors"
          :key="color.name"
          class="color-item"
        >
          <div class="color-swatch">
            <img v-if="color.image" :src="color.image" :alt="color.name" />
          </div>
          <div class="color-name">{{ color.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ColorSelector",
  data() {
    return {
      categories: [
        {
          name: "Korpusfarben",
          colors: [
            {
              name: "stonegrey",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/c/6/csm_035_stone_grey_703d993dca.png",
            },
            {
              name: "Dekor-Druck Eiche Sanremo hell",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/f/d/csm_050_Dekor-Druck_Eiche_Sanremo_hell_e3a947fb5b.png",
            },
            {
              name: "Dekor-Druck Eiche Sonoma",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/1/b/csm_059_Dekor-Druck_Eiche_Sonoma_0095a71152.png",
            },
            {
              name: "seidengrau",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/f/7/csm_068_seidengrau_1bba59bb0a.png",
            },
            {
              name: "Dekor-Druck Eiche Stirling",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/0/8/csm_077_Dekor-Druck_Eiche_Stirling_5cd0e8dfd3.png",
            },
            {
              name: "Dekor-Druck Eiche Wotan",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/0/3/csm_079_Dekor-Druck_Eiche_Wotan_47bc9b8831.png",
            },
            {
              name: "grau metallic",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/1/8/csm_082_grau-metallic_58efbbf10e.png",
            },
            {
              name: "alpinweiß",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/1/c/csm_045_alpinweiß_9299d6be7d.png",
            },
            {
              name: "Dekor-Druck Eiche Artisan",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/e/6/csm_072_Dekor-Druck_Eiche_Artisan_ef71441e92.png",
            },
          ],
        },
        {
          name: "Frontfarben",
          colors: [
            {
              name: "stonegrey",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/c/6/csm_035_stone_grey_703d993dca.png",
            },
            {
              name: "Dekor-Druck Eiche Sanremo hell",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/f/d/csm_050_Dekor-Druck_Eiche_Sanremo_hell_e3a947fb5b.png",
            },
            {
              name: "Dekor-Druck Eiche Sonoma",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/1/b/csm_059_Dekor-Druck_Eiche_Sonoma_0095a71152.png",
            },
            {
              name: "lavagrau",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/4/6/csm_062_lavagrau_d182243a7f.png",
            },
            {
              name: "seidengrau",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/f/7/csm_068_seidengrau_1bba59bb0a.png",
            },
            {
              name: "Dekor-Druck Eiche Stirling",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/0/8/csm_077_Dekor-Druck_Eiche_Stirling_5cd0e8dfd3.png",
            },
            {
              name: "Dekor-Druck Eiche Wotan",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/0/3/csm_079_Dekor-Druck_Eiche_Wotan_47bc9b8831.png",
            },
            {
              name: "grau metallic",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/1/8/csm_082_grau-metallic_58efbbf10e.png",
            },
            {
              name: "Hochglanz Soft Grey",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/2/e/csm_0A4_Hochglanz_soft_grey_529eab0865.png",
            },
            {
              name: "Effektgrau Hochglanz",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/2/9/csm_0R6_Hochglanz_Effektgrau_107c680478.png",
            },
            {
              name: "alpinweiß",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/1/c/csm_045_alpinweiß_9299d6be7d.png",
            },
            {
              name: "Hochglanz weiß",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/a/b/csm_0A1_Hochglanz_weiß_6a3d85a1ab.png",
            },
            {
              name: "Dekor-Druck Eiche Artisan",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/e/6/csm_072_Dekor-Druck_Eiche_Artisan_ef71441e92.png",
            },
            {
              name: "Champagner",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/9/c/csm_042_Champagner_62755772f0.png",
            },
          ],
        },
        {
          name: "Glasfarben",
          colors: [
            {
              name: "Kristallglasspiegel",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/5/5/csm_Glas104_Kristallspiegel_78b77bd60a.png",
            },
            {
              name: "Glas seidengrau",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/c/7/csm_Glas168_Glas_seidengrau_5127d3b1e4.png",
            },
            {
              name: "Glas basalt",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/5/7/csm_Glas181_Glas_basalt_c8fe8b52a3.png",
            },
            {
              name: "Kristallweißglas",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/e/5/csm_Glas117_Glas_weiß_a3630e3c7d.png",
            },
            { name: "Glas champagner - glänzend", image: "" },
          ],
        },
        {
          name: "Innenfarben",
          colors: [
            {
              name: "Texline Leinenoptik naturfarben",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/e/1/csm_047_Innenfarbe_Dekor-Druck_Texline_52fd2b7c88.png",
            },
          ],
        },
        {
          name: "Fußfarben",
          colors: [
            {
              name: "alufarbig",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/b/e/csm_007_alufarben_ec04970ac2.png",
            },
            {
              name: "schwarz matt",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/7/f/csm_046_Schwarz_matt_ef6a29dc19.png",
            },
            {
              name: "Wildeiche Massiv",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/7/b/csm_051_Wildeiche_massiv_d1cd3182e5.png",
            },
            {
              name: "seidengrau",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/f/7/csm_068_seidengrau_1bba59bb0a.png",
            },
            {
              name: "grau metallic",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/1/8/csm_082_grau-metallic_58efbbf10e.png",
            },
            {
              name: "alpinweiß",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/1/c/csm_045_alpinweiß_9299d6be7d.png",
            },
          ],
        },
        {
          name: "Grifffarben",
          colors: [
            {
              name: "alufarbig",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/b/e/csm_007_alufarben_ec04970ac2.png",
            },
            {
              name: "Wildeiche Massiv",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/7/b/csm_051_Wildeiche_massiv_d1cd3182e5.png",
            },
            {
              name: "grau metallic",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/1/8/csm_082_grau-metallic_58efbbf10e.png",
            },
            {
              name: "alpinweiß",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/1/c/csm_045_alpinweiß_9299d6be7d.png",
            },
            {
              name: "chromfarben",
              image:
                "https://www.rauchmoebel.de/fileadmin/_processed_/9/6/csm_004_chromfarben_b8d8e561bb.png",
            },
            { name: "Lederoptik Cognacbraun", image: "" },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.color-selector {
  padding: 30px 0 0;
  border-top: 1px solid #e0e0e0;
}

.color-category {
  & + .color-category {
    margin-top: 60px;
  }

  h4 {
    color: var(--main);
    font-weight: 400;
  }

  .color-list {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 -10px;
    text-align: center;

    .color-item {
      width: 33.333333%;
      padding: 0 5px;
      font-weight: 300;
      font-size: 0.8em;

      .color-swatch {
        margin: 15px 0 0;

        img {
          border-radius: 100%;
          border: 1px solid #e0e0e0;
        }
      }
    }
  }
}
</style>
