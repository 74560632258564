<template>
  <div class="model-view">
    <div v-if="product">
      <ImageSlider :images="productImages" />

      <header class="main-header">
        <h2 class="h2-icon">{{ product.name }}</h2>
      </header>

      <div class="model-info">
        <div class="info-section">
          <ProductDescription :product="product" />
        </div>

        <div class="info-section">
          <YouTubeVideo
            videoId="bdKgQs8nKBQ"
            title="Glänzende Schrankvielfalt: Der Kleiderschrank Celle"
          />
        </div>

        <div class="info-section">
          <h3>Unsere Varianten</h3>

          <div class="color-variants">
            <SizeComponent />
            <ColorComponent />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <ul class="model-list">
        <li class="model-item" v-for="prod in products" :key="prod.id">
          <router-link :to="`/produkte/${encodeURIComponent(prod.name)}`">
            <div class="model-image">
              <img :src="getImageUrl(prod.image)" :alt="prod.name" />
            </div>
            <div class="model-content">
              <div class="brand">{{ prod.brand }}</div>
              <div class="name">
                <div class="model-name">{{ prod.name }}</div>
                <div class="model-brand">
                  <object
                    :data="getBrandLogo(prod.brand)"
                    type="image/svg+xml"
                    class="brand-logo"
                  >
                    {{ prod.brand }}
                  </object>
                </div>
              </div>

              <div class="cta">
                <span>Zur Modellseite</span>

                <span class="mdi mdi-arrow-right"></span>
              </div>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import productsData from "@/assets/products.json";
import ImageSlider from "@/components/ImageSlider.vue";
import ColorComponent from "@/components/ColorComponent.vue";
import SizeComponent from "@/components/SizeComponent.vue";
import ProductDescription from "@/components/ProductDescription.vue";
import YouTubeVideo from "@/components/YoutubeVideo.vue";

export default {
  name: "ModelView",
  data() {
    return {
      products: productsData,
      productImages: [],
    };
  },
  components: {
    ImageSlider,
    ColorComponent,
    SizeComponent,
    ProductDescription,
    YouTubeVideo,
  },
  created() {
    this.updatePageTitle();
  },
  methods: {
    updatePageTitle() {
      const productName = this.$route.params.productName;
      if (productName) {
        // If we're on a specific product page
        document.title = `${productName} | rauch Möbelwerke GmbH`;
      } else {
        // If we're on the general products page
        document.title = "Produkte | rauch Möbelwerke GmbH";
      }
    },
    getBrandLogo(brand) {
      const brandMap = {
        "rauch BLUE": "rauch-blue",
        "rauch ORANGE": "rauch-orange",
        "rauch BLACK": "rauch-black",
      };

      const logoName = brandMap[brand];
      return require(`@/assets/marken/${logoName}.svg`);
    },
    getImageUrl(imageName) {
      return require(`@/assets/produkte/${imageName}`);
    },
    async loadProductImages() {
      if (this.product) {
        const imageContext = require.context(
          "@/assets/produkte",
          true,
          /\.(png|jpe?g)$/
        );
        const productImagePaths = imageContext
          .keys()
          .filter((path) => path.includes(`/${this.product.name}/`));

        this.productImages = productImagePaths.map((path) =>
          imageContext(path)
        );
      }
    },
  },
  computed: {
    product() {
      const productName = decodeURIComponent(this.$route.params.productName);
      return this.products.find((p) => p.name === productName);
    },
  },
  watch: {
    product: {
      immediate: true,
      handler() {
        this.loadProductImages();
      },
    },
    "$route.params.productName": "updatePageTitle",
  },
};
</script>

<style lang="scss" scoped>
header {
  &.main-header {
    padding: 30px 0;
    border-bottom: 1px solid #e0e0e0;
    text-align: center;

    h2 {
      display: inline-block;
      margin: 0 auto;
    }
  }
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.model-list {
  padding: 30px 0;
}

.model-item {
  & + .model-item {
    margin-top: 50px;
  }

  a {
    text-decoration: none;
  }

  .model-image {
    img {
      width: 100%;
      height: auto;
    }
  }

  .model-content {
    background-color: var(--bgc);
    margin-left: auto;
    margin-top: -80px;
    padding: 30px 20px 0 40px;
    position: relative;
    width: calc(100% - 40px);

    .brand {
      color: #adadad;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.56px;
      line-height: 1;
      margin-bottom: 15px;
      text-transform: uppercase;
    }

    .name {
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      color: #464646;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      font-size: 26px;
      font-weight: 500;
      justify-content: space-between;
      line-height: 1.15;

      .model-name {
      }

      .model-brand {
        object {
          height: 30px;
          width: auto;
        }
      }
    }

    .cta {
      color: var(--main);
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1px;
      margin-bottom: 0;
      text-transform: uppercase;
      margin-top: 10px;
    }
  }
}
</style>
