<template>
  <div class="category-item">
    <div class="category-headline">
      <h2 class="h2">{{ category.title }}</h2>
    </div>
    <div class="question-list">
      <FaqQuestion
        v-for="(question, index) in category.questions"
        :key="index"
        :question="question.question"
        :answer="question.answer"
      />
    </div>
  </div>
</template>

<script>
import FaqQuestion from "./FaqItem.vue";

export default {
  name: "FaqCategory",
  components: {
    FaqQuestion,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.category-item {
  .category-headline {
    margin-bottom: 20px;

    h2 {
      font-size: 20px;
      font-weight: bold;
    }
  }

  .question-list {
    display: flex;
    flex-direction: column;
  }
}
</style>
