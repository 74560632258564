<!-- FaqView.vue -->
<template>
  <div class="faq-view">
    <header>
      <h2 class="h2-icon">Häufige Fragen</h2>
      <p>
        Willkommen bei den häufig gestellten Fragen (Frequently Asked
        Questions), die Ihnen bei der Kaufentscheidung von rauch Möbeln helfen
        sollen.
      </p>
    </header>
    <div class="section-content">
      <div class="faq-list" id="faq-list">
        <FaqCategory
          v-for="category in categories"
          :key="category.title"
          :category="category"
        />
      </div>
    </div>

    <button class="back-button" @click="goToHome">
      <span class="mdi mdi-chevron-left"></span>
      <span>Zurück zur Startseite</span>
    </button>
  </div>
</template>

<script>
import FaqCategory from "@/components/FaqCategory.vue";

export default {
  name: "FaqView",
  components: {
    FaqCategory,
  },
  data() {
    return {
      categories: [
        {
          title: "Möbelkauf",
          questions: [
            {
              question:
                "Wo ist der nächste Fachhändler, bei dem ich rauch Produkte kaufen kann?",
              answer:
                'Mit unserer Händlersuche können Sie ganz einfach den nächstgelegenen Fachhändler finden:<br><a href="https://www.rauchmoebel.de/rauch-gruppe/haendlersuche">weiter zur Händlersuche</a>',
            },
            {
              question:
                "Kann ich mir die Möbel von rauch vorab planen (lassen)?",
              answer:
                'Viele unserer Fachhändler im Möbelhaus sind mit entsprechender Software ausgestattet, um unsere besonders variantenreichen Produkte zu visualisieren.<br>Für einige ausgewählte Produkte bieten wir auch auf unserer Webseite entsprechende Tools für Sie zur Nutzung an:<br><a href="https://www.rauchmoebel.de/rauch-gruppe/service/rauch-konfigurator">zu den rauch-Möbelkonfiguratoren</a>',
            },
            {
              question:
                "Kann ich Innenausstattungen für meinen rauch-Kleiderschrank kaufen?",
              answer:
                'Ja, alle unsere Fachhändler können Innenausstattungen aus unserem Katalog für Ihren Schrank bestellen:<br><a href="https://www.rauchmoebel.de/rauch-gruppe/haendlersuche">weiter zur Händlersuche</a><br>Unsere Infoseite bieten Ihnen eine gute Übersicht über das verfügbare Zubehörsortiment von rauch:<br><a href="https://www.rauchmoebel.de/zubehoersortiment">weiter zur Innenausstattung</a>',
            },
          ],
        },
        {
          title: "Möbelqualität",
          questions: [
            {
              question:
                "Wo finde ich Informationen über die Gesundheit und Sicherheit der rauch-Produkte?",
              answer:
                'Alle Informationen finden Sie hier:<br><a href="https://www.rauchmoebel.de/rauch-gruppe/qualitaet-und-nachhaltigkeit">rauch – Qualität & Nachhaltigkeit</a>',
            },
            {
              question: "Woher kommt das Holz für die Möbel von rauch?",
              answer:
                'Information zur Holzherkunft finden Sie auf unserer Infoseite zur Holzherkunft:<br><a href="https://www.rauchmoebel.de/rauch-gruppe/qualitaet-nachhaltigkeit/holzherkunft">zur Infoseite "Holzherkunft"</a>',
            },
            {
              question:
                "Wo finde ich Informationen über die Belastbarkeit der Möbel?",
              answer:
                'Die Übersicht finden Sie hier:<br><a href="https://www.rauchmoebel.de/fileadmin/user_upload/rauch_Qualitaet_Nachhaltigkeit/Belastungstabelle.pdf">Belastungstabelle (PDF)</a><br>Bitte beachten Sie: Das Maximalgewicht sollte nicht überschritten werden, sonst besteht die Gefahr, dass Ihre Möbel beschädigt werden.',
            },
          ],
        },
        {
          title: "Möbelmontage",
          questions: [
            {
              question:
                "Gibt es Montagevideos, die den Aufbau der rauch-Möbel erleichtern?",
              answer:
                'Ja, wir bieten zahlreiche Montagevideos zu den verschiedenen Aufbauschritten unserer Möbel an:<br><a href="product_videoanleitung.html#alabama">zur Montagevideo-Seite</a>',
            },
            {
              question:
                "Wo bekomme ich eine Montageanleitung für mein rauch-Möbel?",
              answer:
                'Die Montageanleitung finden Sie direkt hier:<br><a href="product-list.html">Zur Montageanleitungs-Suche</a>',
            },
          ],
        },
        {
          title: "Reklamation und Ersatzteile",
          questions: [
            {
              question: "Wie gehe ich bei einer Reklamation vor?",
              answer:
                'Wenden Sie sich bitte bei Beanstandungen und Reklamationen direkt an den Händler, bei dem Sie Ihr rauch-Möbel gekauft haben. Um Sie hierbei zu unterstützen, finden Sie auf unserer Infoseite eine Beschreibung, wie Sie im Reklamationsfall vorgehen sollten:<br><a href="https://www.rauchmoebel.de/rauch-gruppe/service/beanstandungsformular">Vorgehensweise im Reklamationsfall</a>',
            },
            {
              question: "Wo kann ich Ersatzteile für rauch-Möbel bekommen?",
              answer:
                "Bitte wenden Sie sich direkt an den Händler, bei dem Sie Ihre Möbel ursprünglich gekauft haben, dieser kann die benötigten Ersatzteile für Sie bestellen.",
            },
          ],
        },
      ],
    };
  },
  methods: {
    goToHome() {
      this.$router.push("/");
      this.isMenuOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.faq-view {
  padding: 30px 0;

  header {
    margin-bottom: 30px;

    h2 {
      font-size: 24px;
      margin-bottom: 10px;
    }

    p {
      font-size: 16px;
      line-height: 1.5;
    }
  }

  .section-content {
    .faq-list {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
  }

  .back-button {
    border: 1px solid var(--main);
    background-color: transparent;
    border-radius: 0;
    padding: 20px 15px;
    text-align: center;
    display: block;
    width: 100%;
    font-size: 1em;
    color: var(--main);
    margin: 30px 0 0;
    font-family: "Flexo", sans-serif;

    span.mdi {
      margin-right: 3px;
    }
  }
}
</style>
